import React, { HTMLAttributes } from 'react';
import tw, { styled } from 'twin.macro';

export type StackAlign = 'start' | 'end' | 'center';
export type StackJustify = 'start' | 'end' | 'center' | 'between' | 'around';
export type StackSpacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

interface StackProps {
  isInline?: boolean;
  isReversed?: boolean;
  spacing?: StackSpacing;
  align?: StackAlign;
  justify?: StackJustify;
}
interface Props extends HTMLAttributes<HTMLDivElement>, StackProps {}

const spaceXGenerate = (spacing: StackSpacing) => {
  if (spacing === 1) {
    return tw`space-x-1`;
  }
  if (spacing === 2) {
    return tw`space-x-2`;
  }
  if (spacing === 3) {
    return tw`space-x-3`;
  }
  if (spacing === 4) {
    return tw`space-x-4`;
  }
  if (spacing === 5) {
    return tw`space-x-5`;
  }
  if (spacing === 6) {
    return tw`space-x-6`;
  }
  if (spacing === 7) {
    return tw`space-x-7`;
  }
  if (spacing === 8) {
    return tw`space-x-8`;
  }

  return '';
};

const spaceYGenerate = (spacing: StackSpacing) => {
  if (spacing === 1) {
    return tw`space-y-1`;
  }
  if (spacing === 2) {
    return tw`space-y-2`;
  }
  if (spacing === 3) {
    return tw`space-y-3`;
  }
  if (spacing === 4) {
    return tw`space-y-4`;
  }
  if (spacing === 5) {
    return tw`space-y-5`;
  }
  if (spacing === 6) {
    return tw`space-y-6`;
  }
  if (spacing === 7) {
    return tw`space-y-7`;
  }
  if (spacing === 8) {
    return tw`space-y-8`;
  }

  return '';
};

const generateJustify = (justify: StackJustify) => {
  if (justify === 'around') {
    return tw`justify-around`;
  }
  if (justify === 'between') {
    return tw`justify-between`;
  }
  if (justify === 'center') {
    return tw`justify-center`;
  }
  if (justify === 'end') {
    return tw`justify-end`;
  }

  return tw`justify-start`;
};

const generateAlign = (align: StackAlign) => {
  if (align === 'center') {
    return tw`items-center`;
  }
  if (align === 'end') {
    return tw`items-end`;
  }

  return tw`items-start`;
};

const Container = styled.div<{ dataStyles: Omit<Props, 'className'> }>(
  (props) => {
    const styles = [];
    const {
      spacing = 0,
      align,
      isInline,
      isReversed,
      justify,
    } = props.dataStyles;

    if (isInline) {
      if (spacing > 0) {
        styles.push(spaceXGenerate(spacing));
      }
      if (isReversed) {
        styles.push(tw`flex-row-reverse`);
      }
    } else {
      if (spacing > 0) {
        styles.push(spaceYGenerate(spacing));
      }
      styles.push(isReversed ? tw`flex-col-reverse` : tw`flex-col`);
    }

    if (justify) {
      styles.push(generateJustify(justify));
    }

    if (align) {
      styles.push(generateAlign(align));
    }

    return [...styles, tw`flex`];
  },
);

export const Stack: React.FC<Props> = ({ children, ...props }) => {
  const { isInline, isReversed, spacing, align, justify, ...rest } = props;

  return (
    <Container
      dataStyles={{ isInline, isReversed, spacing, align, justify }}
      {...rest}
    >
      {children}
    </Container>
  );
};
