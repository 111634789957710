import {
  useInView as useInviewNative,
  IntersectionOptions,
  InViewHookResponse,
} from 'react-intersection-observer';

const noop = () => {};

export default function useInview(options: IntersectionOptions) {
  const hookResult = useInviewNative(options);
  if (
    typeof window !== 'undefined' &&
    typeof window.IntersectionObserver === 'undefined'
  ) {
    // Unsupported, return no `ref` and default `inView` true value
    return [noop, true, undefined] as InViewHookResponse;
  }

  return hookResult;
}
