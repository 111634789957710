import React from 'react';
import styled from 'styled-components';

const InlineSvg = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: inherit;
  fill: currentColor;
`;

const SvgWrapper = styled.span<{ size: number }>`
  display: inline-block;
  flex: 0 0 ${({ size }) => (size ? `${size}px` : '24px')};
  width: ${({ size }) => (size ? `${size}px` : '24px')};
  height: ${({ size }) => (size ? `${size}px` : '24px')};
  min-width: ${({ size }) => (size ? `${size}px` : '24px')};
  min-height: ${({ size }) => (size ? `${size}px` : '24px')};
  position: relative;
  color: inherit;
`;

export type IconProps = {
  size?: number;
  className?: string;
} & React.HTMLAttributes<SVGElement>;

export default function createIcon(svg: React.ReactNode, viewBoxSize = 24) {
  return ({ size = 24, className = '', ...props }: IconProps) => (
    <SvgWrapper size={size} className={className}>
      <InlineSvg
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="1.414"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        preserveAspectRatio="xMidYMid meet"
        {...props}
      >
        {svg}
      </InlineSvg>
    </SvgWrapper>
  );
}
