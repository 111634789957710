import React from 'react';
import { keyframes } from 'styled-components';
import tw, { styled } from 'twin.macro';

const fade = (startColor: string, endColor: string) =>
  keyframes({
    from: { backgroundColor: startColor },
    to: { backgroundColor: endColor },
  });

interface SkeletonOptions {
  startColor?: string;
  endColor?: string;
  speed?: number;
  className?: string;
}

const Container = styled.div<{
  animationSpeed: number;
  startColor: string;
  endColor: string;
}>`
  ${tw`pointer-events-none text-transparent rounded-sm`};
  background-color: ${({ startColor }) => startColor};
  animation: ${({ startColor, endColor }) => fade(startColor, endColor)}
    ${({ animationSpeed }) => animationSpeed}s linear infinite alternate;
`;

export const Skeleton = ({
  startColor = '#F7F7F8',
  endColor = '#E8E8E8',
  speed = 0.8,
  className = '',
}: SkeletonOptions) => {
  return (
    <Container
      startColor={startColor}
      endColor={endColor}
      animationSpeed={speed}
      className={className}
    />
  );
};
