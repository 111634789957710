import React, { useState, useEffect, useRef } from 'react';
import tw, { styled, TwStyle } from 'twin.macro';
import { AspectRatioBox } from 'components/AspectRatio';
import useInView from 'hooks/useInView';
import { Skeleton } from 'components/Skeleton';

type ImgObjectFit = 'contain' | 'cover' | 'scale-down' | 'none' | 'fill';

interface ImageProps
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'height' | 'width'> {
  src: string;
  fallbackSrc?: string;
  alt?: string;
  onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  width?: number;
  height?: number;
  ignoreFallback?: boolean;
  objectFit?: ImgObjectFit;
  isLazy?: boolean;
  fixedSize?: boolean;
}

const StyledImg = styled.img<{
  htmlHeight?: number;
  htmlWidth?: number;
  objectFit: ImgObjectFit;
}>(({ htmlHeight, htmlWidth, objectFit }) => {
  const concatStyled: (TwStyle | string)[] = [`object-fit: ${objectFit};`];
  if (htmlHeight) {
    concatStyled.push(`height:${htmlHeight}px;`);
  }
  if (htmlWidth) {
    concatStyled.push(`width:${htmlWidth}px;`);
  }

  return concatStyled;
});

interface LazyWrapperProps extends ImageProps {}

const LazyWrapper: React.FC<LazyWrapperProps> = ({
  children,
  width,
  height,
  fixedSize,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });

  return (
    <AspectRatioBox
      ratio={(width || 0) / (height || 1)}
      ref={ref}
      style={fixedSize ? { width } : undefined}
      css={tw`bg-gray-100 flex-none`}
    >
      {inView ? children : <Skeleton />}
    </AspectRatioBox>
  );
};

interface HasImageLoadedProps {
  src?: string;
  onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

export function useImageLoadState(props: HasImageLoadedProps) {
  const { src, onLoad, onError } = props;
  const ref = useRef(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (!src) {
      return;
    }

    const image = new window.Image();

    image.onload = (event) => {
      if (ref.current) {
        setHasLoaded(true);
        setHasError(false);

        if (onLoad) {
          // @ts-ignore
          onLoad(event);
        }
      }
    };

    image.onerror = (event) => {
      if (ref.current) {
        setHasLoaded(false);
        setHasError(true);

        if (onError) {
          // @ts-ignore
          onError(event);
        }
      }
    };

    image.src = src;
  }, [src, onLoad, onError]);

  useEffect(() => {
    return () => {
      ref.current = false;
    };
  }, []);

  return { hasLoaded, hasError };
}

export const Image = (props: ImageProps) => {
  const {
    src,
    width,
    height,
    alt,
    onLoad,
    onError,
    fallbackSrc,
    ignoreFallback,
    objectFit = 'cover',
    isLazy = false,
    className,
    ...rest
  } = props;

  const { hasLoaded, hasError } = useImageLoadState({ src, onLoad, onError });

  const imageRender = (
    <StyledImg
      height={height}
      width={width}
      objectFit={objectFit}
      alt={alt}
      src={hasError && fallbackSrc ? fallbackSrc : src}
      css={[
        !hasLoaded && !hasError && tw`opacity-0`,
        ((hasLoaded && !hasError) || (hasError && fallbackSrc)) &&
          tw`opacity-100`,
        isLazy && tw`duration-200 transition-opacity`,
      ]}
      className={className}
      {...rest}
    />
  );

  if (isLazy) {
    return <LazyWrapper {...props}>{imageRender}</LazyWrapper>;
  }

  return imageRender;
};
