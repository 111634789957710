import React from 'react';
import { formatCurrency } from 'utils/currency';
import { Text, TextProps } from './typography';

export interface CurrencyProps extends Omit<TextProps, 'as'> {
  children: string | number;
  htmlAs?: TextProps['as'];
}

const Currency = ({ children, htmlAs, ...rest }: CurrencyProps) => {
  return React.createElement(
    Text,
    { ...rest, as: htmlAs },
    formatCurrency(Number(children)),
  );
};

export default React.memo(Currency);
