import tw, { styled } from 'twin.macro';
import { forwardRef, HTMLAttributes } from 'react';

const Container = styled.div<{ aspectRatio: number }>`
  ${tw`relative`}
  &::before {
    ${tw`block`}
    content: '';
    padding-bottom: ${({ aspectRatio }) => (1 / (aspectRatio || 1)) * 100}%;
  }
  > * {
    ${tw`overflow-hidden absolute inset-0 flex items-center justify-center w-full h-full`}
  }
  > img,
  > video {
    ${tw`object-cover`}
  }
`;

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'css'> {
  ratio?: number;
  children?: React.ReactNode;
}

export const AspectRatioBox = forwardRef<HTMLDivElement, Props>(
  ({ ratio = 4 / 3, children, ...rest }, ref?) => (
    <Container aspectRatio={ratio} ref={ref} {...rest}>
      {children}
    </Container>
  ),
);
